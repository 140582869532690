<template>
  <b-container>
    <b-row class="my-4 align-items-center">
      <b-col md="3"
        ><b-img
          :src="require('@/assets/svg/myHubcast-logo.svg')"
          alt="radioshop logo"
          class="w-100"
        >
        </b-img>
      </b-col>
      <b-col>
        <h2 class="text-right">Design Sytem</h2>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h-card>
          <h1 class="heading">Typographie</h1>
          <h1 class="font-weight-bold">Title H1 Bold</h1>
          <h1>Title H1</h1>
          <h2>Title H2</h2>
          <h3>Title H3</h3>
          <h4>Title H4</h4>
          <h5>Title H5</h5>
          <h6>Title H6</h6>
          <b-row>
            <b-col>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, assumenda! Unde, ut delectus. Nesciunt inventore
                blanditiis doloribus voluptates facilis accusantium! Expedita,
                dolorem aperiam vel exercitationem labore suscipit quia officiis
                nostrum.
              </p>
            </b-col>
            <b-col>
              <p class="large">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Suscipit, assumenda! Unde, ut delectus. Nesciunt inventore
                blanditiis doloribus voluptates facilis accusantium! Expedita,
                dolorem aperiam vel exercitationem labore suscipit quia officiis
                nostrum.
              </p>
            </b-col>
          </b-row>
        </h-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <h-card>
          <h1 class="heading">Buttons</h1>
          <b-row>
            <b-col>
              <h-button purple>Purple Gradient</h-button>
              <h-button blue class="ml-4"
                ><b-icon icon="plus" class="mr-2"></b-icon>Créer une
                séquence</h-button
              >
              <h-button purple class="ml-4">
                <b-icon icon="person" class="mr-2"></b-icon>Gradient
              </h-button>
              <h-button green class="ml-4">
                Enregistrer la séquence
              </h-button>
              <h-button red outline class="my-4 ml-4 d-flex align-items-center">
                <b-icon icon="trash-fill" class="w-15px mr-2"> </b-icon
                >Supprimer la planification
              </h-button>
              <h-button outline grey class="ml-4">
                Dupliquer
              </h-button>
            </b-col>
          </b-row>
        </h-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <h-card>
          <h1 class="heading">Forms</h1>
          <b-row>
            <b-col>
              <h-input v-model="input1" label="Input classic"></h-input>
            </b-col>
            <b-col>
              <h-input
                v-model="input2"
                label="Input with description"
                description="This is description"
              ></h-input>
            </b-col>
            <b-col>
              <h-input v-model="input3" label="Input required" :required="true">
              </h-input>
            </b-col>
            <b-col>
              <h-input v-model="input4" label="Input disabled" disabled>
              </h-input>
            </b-col>
          </b-row>
        </h-card>
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col>
        <h-card>
          <h1 class="heading">...</h1>
          <b-row>
            <b-col> </b-col>
          </b-row>
        </h-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      input4: '',
    }
  },
}
</script>

<style lang="scss" scoped></style>
